import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
import { topbarBgGreen } from "../Assets/images";

import {
  validateTextInput,
  validateEmailInput,
  validateFixedCountInput,
  validatePhoneNumber,
  inputMaxCount,
  clearError,
  validateFullPhoneNumber,
} from "../Libs/forms";
import { NavLink, useNavigate } from "react-router-dom";
import { citiesText } from "../Libs/citiesText";
import { useClickOutsideMulti } from "../hooks/useClickOutsideMulti";
//import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import profImgPlaceholder from '../Assets/img/profile/avatar-placeholder.webp'

let streetsArr = null,
  citiesArr = null;

function EditAddressPopup({
  closeShowEditAdressPopup,
  currUserObject,
  setCurrUserObject
}) {
  const navigate = useNavigate();


  const [profileInfo, setProfileInfo] = useState({
    fullname: "",
    phoneNumber: "",
    // telSecond: "",
    birthDay: "",
    birthMonth: "",
    birthdayYear: "",
    email: "",
    city: "",
    street: "",
    building: "",
    // addressHouse: "",
    addressApartments: "",
    addressEntrance: "",
    postal: "",
    mailbox: "",
    profileImg: currUserObject?.user?.userPic
  });

  const [citiesObject, setCitiesObject] = useState([]);
  const [citiesWholeObject, setCitiesWholeObject] = useState([]);
  const [formCitiesOpen, setFormCitiesOpen] = useState(false);
  const [formStreetsOpen, setFormStreetsOpen] = useState(false);
  const [streets, setStreets] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);

  const [selectedDate, setSelectedDate] = useState({
    date: `${profileInfo.birthDay}-${profileInfo.birthMonth}-${profileInfo.birthdayYear}`,
    day: profileInfo.birthDay,
    month: profileInfo.birthMonth,
    year: profileInfo.birthdayYear,
  });
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const citiesLabelRef = useRef(null);
  const streetsLabelRef = useRef(null);

  console.log(profileInfo, 'profileInfo')

  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) return;
    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      // Send the image data to the API
      const response = await axios.post(`${API_DOMAIN}/api/ProductCategory/upload`, formData);

      // Handle the API response here if needed
      console.log("Image uploaded successfully", response.data);

      // Update the profile image URL in your state
      const imageURL = response.data;
      setProfileInfo((prev) => ({ ...prev, profileImg: imageURL }));

      // Reset the input value to clear the selection
      e.target.value = "";
    } catch (error) {
      // Handle any errors that occurred during the upload
      console.error("Error uploading image", error);
    }
  };
  const goToCartByWishlist1 = () => {
    debugger;
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
        //  setCurrWishlist({
        //    whishlistItemsList: response.data.whishlistItemsList,
          //  whishlistObject: response.data.whishlistObject,
            //whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          //});

          navigate(`/cart/${whishLists[whishLists.length - 1].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
    //    alert("Error, please try again");
      });

  };

  const handleDateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    setSelectedDate({
      date: result.date,
      day: result.day,
      month: result.month,
      year: result.year,
    });
  };
  const parseDate = (value, dayInput, monthInput, yearInput) => {
    if (value !== "") {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      clearError(dayInput);
      clearError(monthInput);
      clearError(yearInput);
      return { date, day, month, year };
    } else {
      const date = "";
      const day = "";
      const month = "";
      const year = "";
      return { date, day, month, year };
    }
  };

  const submitProfileForm = (e) => {
    e.preventDefault();

    const form = e.target;

    const fullnameInput = form["fullname"];
    const telInputFirst = form["tel-1"];
    // const telInputSecond = form["tel-2"];
    const emailInput = form["email"];
    const birthdayInputYear = form["birthday-year"];
    const birthdayInputMonth = form["birthday-month"];
    const birthdayInputDay = form["birthday-day"];
    const cityInput = form["city"];
    const streetInput = form["street"];
    const buildingInput = form["house-num"];
    const addressApartmentInput = form["apartment-num"];
    const addressEntranceInput = form["entrance"];
    const mailboxInput = form["mailbox"];
    const postalInput = form["postal-code"];

    const isFullname = validateTextInput(fullnameInput, 3);
    const isTelFirst = validateFullPhoneNumber(telInputFirst);
    // const isTelFirst = validateFixedCountInput(telInputFirst, 7);
    // const isTelSecond = validatePhoneNumber(telInputSecond);
    const isEmail = validateEmailInput(emailInput);
    const isBirthYear = validateTextInput(birthdayInputYear, 4);
    const isBirthMonth = validateTextInput(birthdayInputMonth, 1);
    const isBirthDay = validateTextInput(birthdayInputDay, 1);
    const isCity = validateTextInput(cityInput, 1);
    const isStreet = validateTextInput(streetInput, 1);
    const isBuilding = validateTextInput(buildingInput, 1);
 //   const isAddressApartment = validateTextInput(addressApartmentInput, 1);
 //   const isAddressEntrance = validateTextInput(addressEntranceInput, 1);
    // const isMailboxInput = validateTextInput(mailboxInput, 1);
    //const isPostalInput = validateTextInput(postalInput, 1);

    const isFormValid =
      isFullname &&
      isTelFirst &&
      // isTelSecond &&
      isBirthYear &&
      isBirthMonth &&
      isBirthDay &&
      isEmail &&
      isCity &&
      isStreet &&
      isBuilding// &&
    //  isAddressApartment
      // &&
      //isAddressEntrance &&
      // isMailboxInput &&
    //  isPostalInput;

    if (isFormValid) {
      const result = {
        email: emailInput.value,
        firstName: fullnameInput.value.split(" ")[0],
        lastName: fullnameInput.value.split(" ")[1],
        phoneNumber: telInputFirst.value,
        companyName: currUserObject.user.companyName,
        bankAccountId: currUserObject.user.bankAccountId,
        postal: form["postal-code"].value,
        mailbox: form["mailbox"].value,
        birthdate:
          birthdayInputDay.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputYear.value,
        entranceNumber: addressEntranceInput.value,
        apartmentNumber: addressApartmentInput.value,
        houseNumber: buildingInput.value,
        userId: currUserObject.user.id,
        city: cityInput.value,
        street: streetInput.value,
        userPic: profileInfo.profileImg,

        // isRemember: form["isRemember"].checked,
      };

      axios
        .post(
          `${API_DOMAIN}/api/Authenticate/UpdateUserDetails`,
          result
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {

            debugger;
            console.log("data that we have got", response.data);
             setCurrUserObject(response.data);
             localStorage.setItem(
               "currUserObject",
               JSON.stringify(response.data)
            );
            setCurrUserObject(response.data);
            localStorage.setItem(
              "currUserObject",
              JSON.stringify(response.data)
            );
            alert("הפרטים שלך נשמרו!");
          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
       //   alert("Error, please try again");
        });

      console.log(result);
    }
  };


  const handleCitiesOpen = () => {
    const newValue = !formCitiesOpen;
    setFormCitiesOpen(newValue);
  };
  const handleSearch = (input, searchList) => {
    const searchText = input.value.toLowerCase();

    for (let i = 0; i < searchList.length; i++) {
      const item = searchList[i];
      const itemName = item.textContent.toLowerCase();

      if (itemName.includes(searchText)) {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "inline";
        }
      } else {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "none";
        }
      }
    }
  };
  const handleStreetsOpen = () => {
    const newValue = !formStreetsOpen;
    setFormStreetsOpen(newValue);
  };

  function parseCSVData(csvData) {
    const results = [];
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = currentLine[j].trim();
      }
      results.push(entry);
    }

    return results;
  }

  const { addClickOutsideRef, removeClickOutsideRef } = useClickOutsideMulti();
  const onClickOutsideCitiesHandler = () => {
    // setCitiesObject([]);
    setFormCitiesOpen(false);
  };

  const onClickOutsideStreetsHandler = () => {
    // setStreets([]);
    setFormStreetsOpen(false);
  };

  function citySelectHandler(city) {
    setSelectedCity(city["city_name"]);
    // const cityId = city["city_code"];
    // console.log('citiesWholeObject',citiesWholeObject)
    // const streetsArray = citiesWholeObject
    //   .filter((obj) => obj["city_code"].trim() === cityId.trim())
    //   .map((obj) => obj["street_name"]);
    // setCitiesObject([]);
    setFormCitiesOpen(false);

    const streetsArray = citiesWholeObject
      .filter((obj) => obj["city_name"].trim() === city["city_name"].trim())
      .map((obj) => obj["street_name"]);

    console.log(streetsArray);
    setSelectedStreet("");
    setStreets(streetsArray);
    // clearError(document.getElementById("form-credentials-section__city"));
  }
  function streetSelectHandler(street) {
    // setSelectedStreet(street["street_name"]);
    setSelectedStreet(street);
    setFormStreetsOpen(false);
    // clearError(document.getElementById("form-credentials-section__street"));
    // setStreets([]);
  }

  useEffect(() => {
    const ourObject = parseCSVData(citiesText);
    console.log("citiesList", ourObject);
    let citiesWholeList = ourObject.map(({ city_code, city_name }) => ({
      city_code,
      city_name,
    }));
    let streetsWholeList = ourObject.map(({ street_code, street_name }) => ({
      street_code,
      street_name,
    }));

    citiesArr = citiesWholeList.reduce((result, current) => {
      const { city_code, city_name } = current;
      const isDuplicate = result.some(
        (city) => city.city_code === city_code && city.city_name === city_name
      );

      if (!isDuplicate) {
        result.push({ city_code, city_name });
      }

      return result;
    }, []);

    console.log("citiesArr", citiesArr);
    setCitiesWholeObject(ourObject);

    const streetsArrSorted = streetsWholeList.reduce((result, current) => {
      const { street_code, street_name } = current;
      const streetKey = `${street_code}_${street_name}`;

      if (!result.hasOwnProperty(streetKey)) {
        result[streetKey] = { street_code, street_name };
      }

      return result;
    }, {});

    const uniqueStreets = Object.values(streetsArrSorted);
    streetsArr = uniqueStreets;
    console.log("streetsDistinctList", uniqueStreets);
  }, []);
  useEffect(() => {
    addClickOutsideRef(citiesLabelRef, onClickOutsideCitiesHandler);
    addClickOutsideRef(streetsLabelRef, onClickOutsideStreetsHandler);

    // Cleanup the click outside handlers and their refs
    return () => {
      removeClickOutsideRef(citiesLabelRef);
      removeClickOutsideRef(streetsLabelRef);
    };
  }, [addClickOutsideRef, removeClickOutsideRef]);

  useEffect(() => {
    if (!currUserObject) {
      navigate("/credentials");
    } else {
      console.log("our current user", currUserObject.user);
      setProfileInfo({
        fullname: currUserObject.user?.firstName + " " + currUserObject.user?.lastName,
        phoneNumber: currUserObject.user?.phoneNumber,
        // telSecond: currUserObject.user.phoneNumber.slice(0, 3),
        birthDay: currUserObject.user?.birthdate
          ? currUserObject.user?.birthdate.slice(8, 10)
          : "",
        birthMonth: currUserObject.user?.birthdate
          ? currUserObject.user.birthdate.slice(5, 7)
          : "",
        birthdayYear: currUserObject.user?.birthdate
          ? currUserObject.user?.birthdate.slice(0, 4)
          : "",
        email: currUserObject.user?.email,
        city: currUserObject.user?.city,
        street: currUserObject.user?.street,
        building: currUserObject.user?.building,
        // addressHouse: currUserObject.user.houseNumber,
        addressApartments: currUserObject.user?.apartmentNumber,
        addressEntrance: currUserObject.user?.entranceNumber,
        postal: currUserObject.user?.postal,
        mailbox: currUserObject.user?.mailBox,
        houseNumber: currUserObject.user?.houseNumber,
        profileImg: currUserObject.user?.userPic
      });
      setSelectedCity(currUserObject.user?.city);
      setSelectedStreet(currUserObject.user?.street);
    }
  }, []);
  useEffect(() => {
    setSelectedDate({
      date: `${profileInfo.birthDay}-${profileInfo.birthMonth}-${profileInfo.birthdayYear}`,
      day: profileInfo.birthDay,
      month: profileInfo.birthMonth,
      year: profileInfo.birthdayYear,
    });
  }, [profileInfo]);

  return (
    <div
      className="popup_show"
      style={{
        margin: '17vh auto',
        position: "fixed",
        zIndex: 9999,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
     //   backgroundColor: "rgba(0,0,0,0.5)",
        overflowY: "none"

      }}
    >
      <div
        style={{
          maxWidth: "26.927083vw",
          margin: "5rem auto",
          borderRadius: "0.9375rem",
          background: "#fff",
          padding: "2rem",
          position: "relative",
          minHeight: 'none'
        }}
      >
        {/* Close button */}
        <button
          type="button"
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            cursor: "pointer",
            background: "transparent",
            border: "none",
            fontSize: "1.2rem"
          }}
          onClick={closeShowEditAdressPopup}
        >
          ✕
        </button>
        <main className="edit-profile" style={{paddingTop: '0'}}>
     
     

      <section className="edit-profile__main main-edit-profile" style={{paddingTop: '0', paddingBottom: '0'}}>
        <div className="main-edit-profile__container">

          <div className="main-edit-profile__form-block">

           

            <form
              action="#"
              onSubmit={submitProfileForm}
              className="main-edit-profile__form form-edit-profile"
            >
              <div className="form-edit-profile__form-body" style={{marginBottom: '0'}}>
           
                  
                <div className="form-edit-profile__col" style={{flex: '100%'}}>
                  {/* <label
                    htmlFor="form-edit-profile__address"
                    className="form-edit-profile__input-wrapper"
                  >
                    <span className="input-title">כתובת מגורים</span>
                    <input
                      autoComplete="off"
                      type="text"
                      name="address"
                      id="form-edit-profile__address"
                      className="input"
                      defaultValue={profileInfo.city}
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateTextInput(e.target, 1);
                        }
                      }}
                    />
                    <span className="error-text">Minimum 1 char</span>
                  </label> */}
                  <div
                    ref={citiesLabelRef}
                    // htmlFor="form-credentials-section__address"
                    className="form-edit-profile__input-wrapper input-wrapper label"
                  >
                    <span className="input-title">עיר</span>
                    <button
                      type="button"
                      onClick={handleCitiesOpen}
                      className="city-street-select-placeholder"
                    ></button>
                    <input
                       style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}
                      autoComplete="off"
                      type="text"
                      name="city"
                      id="form-edit-profile__city"
                      className="input input-address"
                      // defaultValue={registerFormInfo.address}
                      value={selectedCity}
                    />
                    {formCitiesOpen && (
                      <>
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        >
                          <div
                            className="bs-searchbox"
                            style={{ padding: "0.3125rem 0.5rem" }}
                          >
                            <input
                                                   style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                              type="search"
                              className="input"
                              autoComplete="off"
                              aria-label="Search"
                              aria-controls="bs-select-1"
                              aria-autocomplete="list"
                              aria-activedescendant="bs-select-1-66"
                              onInput={(e) => {
                                const input = e.target;
                                const searchList =
                                  citiesLabelRef.current.querySelectorAll(
                                    ".dropdown-menu.inner li .dropdown-item .text"
                                  );
                                handleSearch(input, searchList);
                              }}
                            />
                          </div>
                          {citiesArr.map((city, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                role="option"
                                className="dropdown-item"
                                id={`bs-select-1-${index}`}
                                tabIndex={0}
                                onClick={() => citySelectHandler(city)}
                              >
                                <span className="text">
                                  {city["city_name"]}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    <span className="error-text">You must select city</span>
                  </div>
                  {/* <p className="form-title">כתובת</p> */}
                  <div className="street-wrapper">
                    <div
                      ref={streetsLabelRef}
                      className="form-edit-profile__input-wrapper input-wrapper street label"
                    >
                      <span className="input-title">רחוב</span>

                      <button
                        type="button"
                        onClick={handleStreetsOpen}
                        className="city-street-select-placeholder"
                      ></button>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="text"
                        name="street"
                        id="form-edit-profile__street"
                        className="input"
                        value={selectedStreet}
                      />
                      {selectedCity && formStreetsOpen && (
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        >
                          <div
                            className="bs-searchbox"
                            style={{ padding: "0.3125rem 0.5rem" }}
                          >
                            <input
                                                   style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                              type="search"
                              className="input"
                              autoComplete="off"
                              aria-label="Search"
                              aria-controls="bs-select-2"
                              aria-autocomplete="list"
                              onInput={(e) => {
                                const input = e.target;
                                const searchList =
                                  streetsLabelRef.current.querySelectorAll(
                                    ".dropdown-menu.inner li .dropdown-item .text"
                                  );
                                handleSearch(input, searchList);
                              }}
                            />
                          </div>
                          {streets.map((street, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                role="option"
                                className="dropdown-item"
                                id={`bs-select-1-${index}`}
                                tabIndex={0}
                                onClick={() => streetSelectHandler(street)}
                              >
                                <span className="text">
                                  {/* {street["street_name"]} */}
                                  {street}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                      <span className="error-text">חובה לבחור רחוב</span>
                    </div>
                  </div>
                  <div className="form-edit-profile__address-details" style={{marginTop: '1rem'}}>
                    <label
                      htmlFor="form-edit-profile__house-num"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">מס' בית</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="house-num"
                        id="form-edit-profile__house-num"
                        className="input"
                        defaultValue={profileInfo.houseNumber}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                      <span className="error-text">לפחות תו 1</span>
                    </label>
                    <label
                      htmlFor="form-edit-profile__apartment-num"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">דירה</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="apartment-num"
                        id="form-edit-profile__apartment-num"
                        className="input"
                        defaultValue={profileInfo.addressApartments}
                     
                      />
                    </label>
                    <label
                      htmlFor="form-edit-profile__entrance"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">כניסה</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="text"
                        name="entrance"
                        id="form-edit-profile__entrance"
                        className="input"
                        defaultValue={profileInfo.addressEntrance}
               
                      />
                    </label>
                  </div>
                  <div className="form-edit-profile__mail" style={{marginTop: '0.4rem'}}>
                    <label
                      htmlFor="form-edit-profile__mailbox"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">ת.ד</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="mailbox"
                        id="form-edit-profile__mailbox"
                        className="input"
                        defaultValue={profileInfo.mailbox}
                
                      />
                    </label>
                    <label
                      htmlFor="form-edit-profile__postal-code"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">מיקוד</span>
                      <input
                                             style={{padding: '1.5vh 0.8vh 0.8vh 0.8vh'}}

                        autoComplete="off"
                        type="number"
                        name="postal-code"
                        id="form-edit-profile__postal-code"
                        className="input"
                        defaultValue={profileInfo.postal}
                    
                      />
                    </label>
                  </div>
                  <div className="form-edit-profile__checkbox checkbox">
                    <input
                      id="c_1"
                      className="checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      name="isRemember"
                    />
                   
                  </div>
                </div>
                <div className="form-edit-profile__col" style={{visibility: 'hidden', width: '0.1px', flex:'none'}}>
                 
                 <input
                   type ="hidden"
                   autoComplete="off"
                   name="fullname"
                   id="form-edit-profile__fullname"
                   className="input"
                   defaultValue={profileInfo.fullname}
                   onInput={(e) => {
                     if (
                       e.target
                         .closest("label")
                         .classList.contains("input-error")
                     ) {
                       validateTextInput(e.target, 3);
                     }
                   }}
                 />
     
                 <input
                   autoComplete="off"
                   type="hidden"
                   name="tel-1"
                   id="form-edit-profile__tel-1"
                   defaultValue={profileInfo.phoneNumber}
                   className="input"
                   onInput={(e) => {
                     inputMaxCount(e.target, 10);
                     if (
                       e.target
                         .closest("label")
                         .classList.contains("input-error")
                     ) {
                       // validateFixedCountInput(e.target, 7);
                       validateFullPhoneNumber(e.target);
                     }
                   }}
                 />
           
        
                 <input
                   autoComplete="off"
                   type="hidden"
                   name="email"
                   defaultValue={profileInfo.email}
                   id="form-edit-profile__email"
                   className="input"
                   onInput={(e) => {
                     if (
                       e.target
                         .closest("label")
                         .classList.contains("input-error")
                     ) {
                       validateEmailInput(e.target);
                     }
                   }}
                 />
       
                   <input
                     type="hidden"
                     className="birthday-field__hidden-main-field"
                     value={selectedDate.date}
                     onChange={(e) => {
                       handleDateChange(
                         e.target,
                         dayRef.current,
                         monthRef.current,
                         yearRef.current
                       );
                     }}
                   />
          
                     <input
                       autoComplete="off"
                       type="hidden"
                       name="birthday-year"
                       id="form-edit-profile__birthday-year"
                       className="input"
                       ref={yearRef}
                       value={selectedDate.year}
                       onChange={(event) => { }}
                     />
                    
                
                     <input
                       autoComplete="off"
                       type="hidden"
                       name="birthday-month"
                       id="form-edit-profile__birthday-month"
                       className="input"
                       ref={monthRef}
                       value={selectedDate.month}
                       onChange={(event) => { }}
                     />
        
                     <input
                       autoComplete="off"
                       type="hidden"
                       name="birthday-day"
                       id="form-edit-profile__birthday-day"
                       className="input"
                       ref={dayRef}
                       value={selectedDate.day}
                       onChange={(event) => { }}
                     />
                 </div>
              </div>
              <div
                className="form-edit-profile__btn-container btn-container_center"

              >
                <button type="submit" className="btn" style={{marginBottom: '1rem'}}>
                  שמירה
                </button>
              </div>
              <div className="form-edit-profile__btn-container btn-container_center">
                <button
                  type="button"
                  className="btn btn-orange"
                  onClick={() => closeShowEditAdressPopup()}
                >
               ביטול
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      
    </main>
      </div>
    </div>
  );
}

export default EditAddressPopup;
