import React, { useEffect } from "react";
import CategoryCard from "../Components/CategoryCard";
import { NavLink, useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { useState } from "react";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import ProductsFilter from "../Components/products-filter";
import { useLocation } from 'react-router-dom';

export default function WishlistCategories({
  newEvent,
 // currentWishListCategories,
  setShowCategMenuItem,
  setFilterObject,
  eventType,
  setEventType,
 // setCurrWishlist,
 // allCategoriesList,
  setProductsFilterObject,
  productsFilterObject,
  //currWishlist,
  isLoggedIn,
  filterToggle
}) {
  const [currentWishListCategories, setCurrentWishListCategories] = useState([]);
  const [allCategoriesList, setAllCategoriesList] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const wishlistId = queryParams.get('wishlistId');
  const navigate = useNavigate();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedCategoriesMobile, setSelectedCategoriesMobile] = useState([])
  const [currWishlist, setCurrWishlist] = useState([])
  const [both, setBoth] = useState(false);
  useEffect(() => {
    axios
      .post(`${API_DOMAIN}/api/ProductCategory/getAllFullCategories`, {})
      .then((response) => {
        // console.log(response);
        setAllCategoriesList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    // We only want the handler when the user is *not* logged in

    if (!isLoggedIn) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = ""; 
        // This triggers the generic browser "Are you sure you want to leave?" message
      };

      // Attach the event listener
      window.addEventListener("beforeunload", handleBeforeUnload);

      // Cleanup by removing the event listener to avoid memory leaks
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isLoggedIn]);
  useEffect(() => {
    localStorage.removeItem("filterObject");
    setShowCategMenuItem(true);

    setFilterObject({
      choosedCategoriesList: [],
      manufacturersList: [],
      priceRange: null,
      lastChangedType: "",
    })
    return (
      setShowCategMenuItem(false)
    )
  }, []);

  useEffect(()=>{
    console.log("**************", currWishlist)

  },[currentWishListCategories])


useEffect(()=>{
  getWishlistObject(wishlistId);
},[])
  function getWishlistObject(whishlistId) {
    axios
      .get(`${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          console.log("wishilist object", response.data.whishlistObject);

          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
     
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Error, please try again");
      });
  }
  useEffect(() => {
    if (allCategoriesList && allCategoriesList.length > 0) {
    //  updateCategories();
    }
  }, [currentWishListCategories]);
  

  useEffect(() => {
   debugger;
    if(allCategoriesList.length > 0 && currWishlist !=null ){
   const event = currWishlist?.whishlistObject?.wishListObj;
  //const event = currWishlist.whishlistObject;
    if (event) {
      try {
        const eventObj = JSON.parse(event);
        const mainCategoryStr = eventObj.mainCategory;
        const mainCategory = JSON.parse(mainCategoryStr);
  
        const updatedWishlistMainCat = mainCategory.filter((cat) => cat.checked);
  
        setEventType(eventObj);
  
        const currentSelectedCategoriesIds = updatedWishlistMainCat.map((cat) => cat.id);
  
        const updatedWishCategories = allCategoriesList.filter((category) =>
          currentSelectedCategoriesIds.includes(category.mainCategory.id)
        );
  
        setCurrentWishListCategories(updatedWishCategories);
      } catch (error) {
        console.error("Error parsing event or mainCategory JSON:", error);
      }
    } else {
      console.warn("No event found in localStorage");
    }}
  }, [allCategoriesList, currWishlist]);











  
  useEffect(() => {
//   debugger;
    let allIds = currentWishListCategories.map(obj => obj?.productCategory?.map(item => item.id)).flat();
    setSelectedCategoriesMobile(allIds);
  },[allCategoriesList])
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [currentWishListCategories]);


  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const additionalGift = URLParams?.get("additionalGift")
  const goToCartByWishlist1 = () => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(`/cart/${whishLists[whishLists.length - 1].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

  };
  useEffect(() => {

    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    } else {
    }

    if (!eventType) {
      console.log(eventType);
      const localEventType = localStorage.getItem("eventType");
      if (localEventType) setEventType(JSON.parse(localEventType));

    }
    if (additionalGift) {
      setIsFirstTime(false);
    }



  }, []);


  const navigateToMatchProducts = (e) => {
 //   debugger;
   const { target } = e;
    if (target.closest(".single-category-type__footer")) return;


    const urlCategories = selectedCategoriesMobile.length
      ? selectedCategoriesMobile: "";
   // allCategoriesList.map((item) => item?.id);
    if (newEvent) {
  //    debugger;
      navigate(
        `/products-new-event/568/?wishlistId=${currWishlistId}` +
        (additionalGift ? `&additionalGift=${additionalGift}` : "") +
        `&category=${urlCategories.length ? urlCategories.join() : ""}`
      );
      
    } else {
      navigate(`/products/568/?wishlistId=${currWishlistId}`);
    }
  };
  return (
    <main className="all-categories category">
      {/* <section className="all-categories__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src="img/common/topbar-bg.png" alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              אירוע חדש
              <span className="icon">
                <img src="img/svg/party-icon.svg" alt="party icon" />
              </span>
            </h1>
          </div>
        </div>
      </section> */}
      <section className="category__topbar topbar topbar--small-img">
        <div className="topbar__container">
          <div
            className="topbar__title-wrapper"
            style={{ position: "relative" }}
          >
            {/* <h1 className="topbar__title">מתנות מומלצות ליום הולדת לנשים</h1> */}
            {isFirstTime &&
              <h1 className="topbar__title">

                <p>
                  מתנות מומלצות ל{eventType?.categoryName}
                </p>


              </h1>}
            <div className="topbar__bg-img-ibg topbar__bg-img-ibg--with-shadows topbar__bg-img-ibg--small">
              {/* <img
            src="../img/common/topbar-category.png"
            alt="topbar"
            className="img-people"
          />
          <img
            src="../img/no-people-images/common/topbar-category.png"
            alt="topbar"
            className="img-no-people"
          /> */}
              {isFirstTime &&
                <img
                  src={'https://server.yyyshopping.com' + eventType?.categoryImage}
                  alt={eventType?.categoryName}
                  className="img-people"
                />
              }
            </div>
          </div>
        </div>
      </section>

      <div className="all-categories__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink to="/my-events">אירוע חדש</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>קטגוריות</p>
            </li>
          </ul>
        </div>
      </div>

      <section className="all-categories__main main-all-categories">
        <div className="main-all-categories__container">
          <div className="main-all-categories__body">
            <div className="main-all-categories__steps-numbers numbers-steps step-2">
              <ul className="numbers-steps__list">
                <li className="numbers-steps__list-item">
                  <div
                    className="numbers-steps__step"
                    onClick={() => {
                       navigate('/my-events')
                    }
                    }
                  >
                    1
                  </div>
                </li>
                <li className="numbers-steps__list-item">
                  <div style={{cursor: 'default'}} className="numbers-steps__step">2</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div style={{cursor: 'default'}} className="numbers-steps__step">3</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div style={{cursor: 'default'}} className="numbers-steps__step">4</div>
                </li>
              </ul>
            </div>
            <div className="main-all-categories__titles-block">
              <h2 className="main-all-categories__title"> {/* למימוש הכסף למתנה*/}</h2>
              {!isMobile &&
                <h3 className="main-all-categories__subtitle">
                  {/* /בחר קטגוריה והזמן מתנה לכל מי שתרצה */}
                 בחירת המתנות בחינם אז אל תתקמצנו
                </h3>}
            </div>
            <ul className={`main-all-categories__list`} style={{marginBottom: '7vh'}}>
              {currentWishListCategories &&
                currentWishListCategories?.map((category) => (
                  <CategoryCard
                    newEvent={newEvent}
                    key={category.mainCategory.id}
                    data={category.mainCategory}
                    wishlistId={currWishlistId}
                    allCategoriesList={allCategoriesList}
                    setProductsFilterObject={setProductsFilterObject}
                    productsFilterObject={productsFilterObject}
                    isMobile={isMobile}
                    setIsMobile={setIsMobile}
                    setSelectedCategoriesMobile={setSelectedCategoriesMobile}
                    selectedCategoriesMobile={selectedCategoriesMobile}
                  />
                ))}
            </ul>



          </div>
          {isMobile &&
          <div className="not-scroll">
            <div className="contact-us-form__btn-container btn-container_center">
              <button
                className="btn wishlist"
               onClick={navigateToMatchProducts}
              >
                לשלב הבא
              </button>
            </div>
            </div>}
        </div>
      </section>














      {isLoggedIn &&
        <div className="form-credentials-section__buttons-container mobile">
          <button
            type="submit"
            className="btn footer-mobile"
            style={{ justifyContent: "space-between", display: "flex", overflow: "hidden", padding: "7px" }}

          >
            <div style={{ flex: 1 }}>
              <NavLink
                to="/"
              >
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.516"
                    height="25.445"
                    viewBox="0 0 25.516 25.445"
                  >
                    <path
                      id="home_1_"
                      data-name="home (1)"
                      d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                      transform="translate(0.771 0.601)"
                      fill="none"
                      stroke="#707070"
                      strokeWidth="1.2"
                    />
                  </svg>
                </div>
                <p className="text1">ראשי</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink to="/contact-us" >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height={26}
                    viewBox="0 0 27 26"
                  >
                    <g
                      id="Сгруппировать_1499"
                      data-name="Сгруппировать 1499"
                      transform="translate(1177 -2115)"
                    >
                      <g
                        id="Эллипс_411"
                        data-name="Эллипс 411"
                        transform="translate(-1177 2115)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <g
                        id="Эллипс_412"
                        data-name="Эллипс 412"
                        transform="translate(-1159 2122)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <g
                        id="Эллипс_413"
                        data-name="Эллипс 413"
                        transform="translate(-1175 2132)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                        <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                      </g>
                      <line
                        id="Линия_524"
                        data-name="Линия 524"
                        x2={10}
                        y2={4}
                        transform="translate(-1168.5 2120.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      />
                      <line
                        id="Линия_525"
                        data-name="Линия 525"
                        y1={6}
                        x2={9}
                        transform="translate(-1166.5 2128.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </span>
                <p className="text1">צרו קשר</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
                to="/my-bank"
              >
                <span className="icon">
                  <svg
                    id="Artboard_1"
                    data-name="Artboard 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.901"
                    height="24.498"
                    viewBox="0 0 23.901 24.498"
                  >
                    <path
                      id="Контур_10114"
                      data-name="Контур 10114"
                      d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                      transform="translate(6.338 -33.519)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10115"
                      data-name="Контур 10115"
                      d="M16,54h.891v.891H16V54Z"
                      transform="translate(6.12 -35.737)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10116"
                      data-name="Контур 10116"
                      d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                      transform="translate(-15.361 -30.746)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10117"
                      data-name="Контур 10117"
                      d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                      transform="translate(-21 -29.083)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2304"
                      data-name="Прямоугольник 2304"
                      width="1.4"
                      height="2.8"
                      transform="translate(7.107 2.8)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2305"
                      data-name="Прямоугольник 2305"
                      width="0.7"
                      height="2.8"
                      transform="translate(9.207)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2306"
                      data-name="Прямоугольник 2306"
                      width="0.7"
                      height="2.8"
                      transform="translate(13.407 8.399)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2307"
                      data-name="Прямоугольник 2307"
                      width="1.4"
                      height="1.4"
                      transform="translate(14.807 11.899)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10118"
                      data-name="Контур 10118"
                      d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                      transform="translate(-18.31 -13.535)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10119"
                      data-name="Контур 10119"
                      d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                      transform="translate(-20.664 -14.17)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10120"
                      data-name="Контур 10120"
                      d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                      transform="translate(-42.772 -20.762)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10121"
                      data-name="Контур 10121"
                      d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                      transform="translate(-37.854 -20.969)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2308"
                      data-name="Прямоугольник 2308"
                      width="0.7"
                      height="0.7"
                      transform="translate(9.207 3.5)"
                      fill="#707070"
                    />
                  </svg>
                </span>
                <p className="text1">הקופה שלי</p>

              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
                onClick={() => goToCartByWishlist1()}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.846"
                    height="24.798"
                    viewBox="0 0 19.846 24.798"
                  >
                    <g
                      id="Сгруппировать_74057"
                      data-name="Сгруппировать 74057"
                      transform="translate(0.5 0.5)"
                    >
                      <path
                        id="Artboard_2"
                        data-name="Artboard 2"
                        d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                        transform="translate(-74.992 -18.61)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth={1}
                      />
                      <path
                        id="heart"
                        d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                        transform="translate(-25.26 -15.21)"
                        fill="#ec1b53"
                      />
                    </g>
                  </svg>
                </span>
                <p className="text1">סל המתנות</p>
              </NavLink>
            </div>
            <div style={{ flex: 1 }}>
              <NavLink
                to="/my-events"
              //  onClick={() => setMyEventsActiveTab(0)}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.959"
                    height="22.842"
                    viewBox="0 0 29.959 22.842"
                  >
                    <g
                      id="Сгруппировать_74063"
                      data-name="Сгруппировать 74063"
                      transform="translate(-14 0.5)"
                    >
                      <g
                        id="Artboard_3"
                        data-name="Artboard 3"
                        transform="translate(22.214 0)"
                      >
                        <path
                          id="Контур_38424"
                          data-name="Контур 38424"
                          d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                          transform="translate(-24.307 -20.873)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38425"
                          data-name="Контур 38425"
                          d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                          transform="translate(-20.77 -14.613)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38426"
                          data-name="Контур 38426"
                          d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                          transform="translate(-14.469 -14.389)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_526"
                          data-name="Линия 526"
                          y2="4.793"
                          transform="translate(9.839 13.419)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_527"
                          data-name="Линия 527"
                          y2="8.147"
                          transform="translate(7.443 11.023)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_528"
                          data-name="Линия 528"
                          y2="11.684"
                          transform="translate(5.047 8.445)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <line
                          id="Линия_529"
                          data-name="Линия 529"
                          y2="7.055"
                          transform="translate(2.651 14.033)"
                          fill="none"
                          stroke="#707070"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <circle
                          id="Эллипс_414"
                          data-name="Эллипс 414"
                          cx="1.438"
                          cy="1.438"
                          r="1.438"
                          transform="translate(10.319)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38427"
                          data-name="Контур 38427"
                          d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                          transform="translate(-27.244 -17.922)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38428"
                          data-name="Контур 38428"
                          d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                          transform="translate(-31.988 -10.775)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38429"
                          data-name="Контур 38429"
                          d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                          transform="translate(-20.474 -9.487)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Контур_38430"
                          data-name="Контур 38430"
                          d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                          transform="translate(-28.181 -7.104)"
                          fill="none"
                          stroke="#707070"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                      <g
                        id="Сгруппировать_1710"
                        data-name="Сгруппировать 1710"
                        transform="translate(14 2.322)"
                      >
                        <g
                          id="Контур_10101"
                          data-name="Контур 10101"
                          transform="translate(0 1.369)"
                          fill="#3c9e1c"
                        >
                          <path
                            d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                            stroke="none"
                          />
                          <path
                            d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                            stroke="none"
                            fill="#fff"
                          />
                        </g>
                        <text
                          id="_"
                          data-name="+"
                          transform="translate(6.845 12.059)"
                          fill="#fff"
                          fontSize="11"
                          fontFamily="Assistant-SemiBold, Assistant"
                          fontWeight="600"
                        >
                          <tspan
                            x="2.783"
                            y="0"
                          >
                            +
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                </span>
                <p className="text1">אירוע חדש</p>


              </NavLink>
            </div>

          </button>
        </div>}
    </main>
  );
}
