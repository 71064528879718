import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
import {
  validateFullPhoneNumber,
  clearError
} from "../Libs/forms"; // Assuming this has your phone validation
import { NavLink, useNavigate } from "react-router-dom";
import { citiesText } from "../Libs/citiesText";
import { useClickOutsideMulti } from "../hooks/useClickOutsideMulti";

import profImgPlaceholder from "../Assets/img/profile/avatar-placeholder.webp";

let streetsArr = null,
  citiesArr = null;

function LeaveYourDetailsPopup({
  closeShowLeaveYourDetailsPopup,
  currUserObject,
  setCurrUserObject,
}) {
  const navigate = useNavigate();

  const [profileInfo, setProfileInfo] = useState({
    fullname: "",
    phoneNumber: "",
    birthDay: "",
    birthMonth: "",
    birthdayYear: "",
    email: "",
    city: "",
    street: "",
    building: "",
    addressApartments: "",
    addressEntrance: "",
    postal: "",
    mailbox: "",
    profileImg: currUserObject?.user?.userPic,
  });

  // For phone validation error
  const [phoneError, setPhoneError] = useState("");
  // For optional email hint if user didn't fill phone
const [emailHint, setEmailHint] = useState("כדאי להכניס את האימייל שלך");

  // ------------- MAIN SUBMIT FUNCTION -------------
  const submitProfileForm = (e) => {
    e.preventDefault();

    const form = e.target;
    const phone = form["phone"]; // phone is required
    const email = form["email"].value.trim();

    // Validate phone
    const isPhone = validateFullPhoneNumber(phone);

    // If phone isn't valid, set an error message & (optionally) show email hint
    if (!isPhone) {
      setPhoneError("אנא הזן מספר פלאפון תקין");
      // If the user left email blank, show the hint
      if (!email) {
        setEmailHint("כדאי להכניס את האימייל שלך");
      } else {
        setEmailHint("");
      }
      return; // Do NOT close popup if invalid
    } else {
      setPhoneError("");
      setEmailHint("");
    }

    // If phone is valid, you can close the popup or make your API call
    closeShowLeaveYourDetailsPopup();

    // Example of an API call if needed:
    /*
    const result = {
      phone: phone.value,        // Required
      email: form["email"].value // Optional
    };

    axios
      .post(`${API_DOMAIN}/api/Authenticate/UpdateUserDetails`, result)
      .then((response) => {
        if (response.data.result) {
          setCurrUserObject(response.data);
          localStorage.setItem("currUserObject", JSON.stringify(response.data));
          alert("הפרטים שלך נשמרו!");
          closeShowLeaveYourDetailsPopup();
        } else {
          alert("שגיאה. נסו שוב מאוחר יותר.");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("שגיאה. נסו שוב מאוחר יותר.");
      });
    */
  };

  useEffect(() => {
    // If no user, redirect or handle accordingly
    if (!currUserObject) {
      navigate("/credentials");
    } else {
      // Pre-fill phone/email if available
      setProfileInfo((prev) => ({
        ...prev,
        phoneNumber: currUserObject.user?.phoneNumber || "",
        email: currUserObject.user?.email || "",
      }));
    }
  }, [currUserObject, navigate]);

  return (
    <div className="popup-model"
    style={{minHeight: '120px !important', minWidth: '120px'}}>
<div
  className="popup-container"

>
<button
          type="button"
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            cursor: "pointer",
            background: "transparent",
            border: "none",
            fontSize: "1.2rem",
          }}
          onClick={closeShowLeaveYourDetailsPopup}
        >
          ✕
        </button>

        <h2
          style={{
            marginBottom: "1.4rem",
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          השאר פרטים
        </h2>

        <main className="edit-profile" style={{ paddingTop: "0" }}>
          <section
            className="edit-profile__main main-edit-profile"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <div className="main-edit-profile__container">
              <div className="main-edit-profile__form-block">
                <form
                  onSubmit={submitProfileForm}
                  className="main-edit-profile__form form-edit-profile"
                >
                  <div
                    className="form-edit-profile__form-body"
                    style={{ marginBottom: "0" }}
                  >
                    <div
                      className="form-edit-profile__col"
                      style={{ flex: "100%" }}
                    >
                      {/* Phone field - Required */}
                      <label className="form-edit-profile__input-wrapper">
                        <span className="input-title">
                          <span style={{ color: "red" }}>*</span>פלאפון</span>
                        <input
                          style={{ padding: "1.5vh 0.8vh 0.8vh 0.8vh" }}
                          autoComplete="off"
                          type="text"
                          name="phone"
                          className="input"
                          defaultValue={profileInfo.phoneNumber}
                        />
                        {phoneError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "0.85rem",
                              marginTop: "4px",
                              display: "inline-block",
                            }}
                          >
                            {phoneError}
                          </span>
                        )}
                      </label>

                      {/* Email field - Optional */}
                      <label className="form-edit-profile__input-wrapper">
                        <span className="input-title">מייל  </span>
                        <input
                          style={{ padding: "1.5vh 0.8vh 0.8vh 0.8vh" }}
                          autoComplete="off"
                          type="text"
                          name="email"
                          className="input"
                          defaultValue={profileInfo.email}
                        />
                        {emailHint && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "0.85rem",
                              marginTop: "4px",
                              display: "inline-block",
                            }}
                          >
                            {emailHint}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div
                    className="form-edit-profile__btn-container btn-container_center"
                    style={{ marginBottom: "0.6rem", marginTop: "1.7rem" }}
                  >
                    <button type="submit" className="btn">
                      שמירה
                    </button>
                  </div>
                  <div className="form-edit-profile__btn-container btn-container_center">
                    <button
                      type="button"
                      className="btn btn-orange"
                      onClick={closeShowLeaveYourDetailsPopup}
                    >
                      ביטול
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>

          {/* MOBILE FOOTER BUTTONS or other content if needed */}
        </main>
      </div>
    </div>
  );
}

export default LeaveYourDetailsPopup;
