import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function YourEventPopup({ setMyEventsActiveTab }) {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(4);

  // Start the countdown as soon as the popup is rendered
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Once the countdown hits 0, navigate and set the tab
  useEffect(() => {
    if (countdown <= 0) {
      setMyEventsActiveTab(1);
      navigate("/my-events");
    }
  }, [countdown, navigate, setMyEventsActiveTab]);

  return (
    <div className="popup-model">
      <div className="popup-container">
        <div
          style={{
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ lineHeight: '1.2', fontSize: "1.2rem", color: "green" }}>
            נכנסת לתשלום מתנות באירוע שלך, מעבירים אותך לאירועים שלך
          </h1>
 

          <div style={{ marginTop: "2rem", width: '70%'}}>  
            <p style={{lineHeight: '1.2', fontSize: "1.0rem", color: "green"}}>מעבר עוד {countdown} שניות</p>

            <button onClick={() => {setMyEventsActiveTab(1); navigate('/my-events')}} className="btn" style={{marginBottom: "0.7rem", width: "100%", padding: "0.3rem", fontSize: "1rem", marginTop:'1rem'}}>אישור</button>
            </div>

        </div>
      </div>
    </div>
  );
}

export default YourEventPopup;
