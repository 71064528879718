

import {NavLink, useNavigate, useLocation, useParams } from "react-router-dom";





function YouHaveThisEventPopup({ onConfirm, closePopupYouHaveThisEvent, EventIdExist }) {

    const navigate = useNavigate();
  
  return (
    <div className="popup-model"
    style={{minHeight: '120px !important', minWidth: '120px', zIndex: '99999999'}}>
<div
  className="popup-container"

>
      <button
          onClick={closePopupYouHaveThisEvent}
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          ✖
        </button>
        <div
          style={{
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "1.5rem", color: "green" }}>כבר פתחת אירוע כזה, תרצה להמשיך אותו?</h1>
          <div style={{ marginTop: "2rem", width: '63%' }}>

          </div>
          <div className="form-edit-profile__btn-container btn-container_center"
          style={{width: '100%'}}>
                <button
                  type="button"
                  className="btn btn-orange"
                  style={{backgroundColor: '#3c9e1c'}}
                 onClick={() => navigate(`/all-categories-new-event?wishlistId=${EventIdExist}`)}
                >
               כן
                </button>
              </div>
        </div>
      </div>
    </div>
  );
}

export default YouHaveThisEventPopup;