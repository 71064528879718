
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";








function BlessingIsEmpty({  setIsEmptyBlessingPopupOpen,
  setResultObject,
  setWriteBlessingStepInfo,
  tempBlessingTitle,
  tempBlessingText,
  goToMoneyPresentStep,
  closeShowPopup }) {
    const navigate = useNavigate();
  
  return (
    <div

    className="popup-model"

    >
      {/* Popup container */}
      <div
            className="popup-container "

      >
        {/* Close button */}
        <button
          onClick={closeShowPopup}
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          ✖
        </button>
        <div
          style={{
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p style={{ fontSize: "1rem", margin: "0.5rem 0" }}>
          לא הזנת ברכה
          </p>
          <div style={{ marginTop: "2rem"}}>
            <button
  onClick={() => {
    // Close popup and move on to next step
    setIsEmptyBlessingPopupOpen(false);

    // Optionally store the empty values to resultObject anyway
    setResultObject((prev) => ({
      ...prev,
      blessingTitle: tempBlessingTitle || "",
      blessingText: tempBlessingText || "",
    }));

    setWriteBlessingStepInfo({
      blessingTitle: tempBlessingTitle,
      blessingText: tempBlessingText,
    });
  goToMoneyPresentStep();
    navigate('money-present');
  }}              className="btn"
              style={{
                marginBottom: "0.7rem",
                width: "100%",
                padding: "0.3rem",
                fontSize: "1rem",
              }}
            >
             אישור
            </button>
            <button
                 onClick={() => {
                  // Close popup to allow editing
                  setIsEmptyBlessingPopupOpen(false);
                }}
              className="btn btn-orange"
              style={{
                width: "100%",
                padding: "0.3rem",
                fontSize: "1rem",
              }}
            >
              עריכה
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlessingIsEmpty;