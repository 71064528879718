import React, { useEffect } from "react";
import { blessingBg, blessingBgNoPeople } from "../Assets/images";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

//import { useNavigate } from "react-router-dom";
//import { useEffect } from "react";

function ThankPayme({setMyEventsActiveTab}) {

  const iframe = document.getElementById('myIframe');

const iframeUrl=window.location.href;
//console.log("^^^^^^^^^^", iframeUrl)
  const urlParams = new URLSearchParams(iframeUrl.split('?')[1]);
  useEffect(() => {
  //  console.log("#########",iframeUrl, window.parent)
    // Send a message to the parent window indicating that the thank you page has been reached
    if(window.parent){
     // console.log("yessss")
    window.parent.postMessage(JSON.stringify({iframeUrl, message:"thank_you_page"}), '*');}
  }, []);
    const navigate = useNavigate();

  return (
    <main className="successful-payment" data-fullscreen>
      <section className="successful-payment__main main-successful-payment">
        <div className="main-successful-payment__bg-image-ibg">
          <img src={blessingBg} className="img-people" alt="bg" />
          <img src={blessingBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="main-successful-payment__content-window">
          <h1 className="main-successful-payment__title">להתראות</h1>
          <h2 className="main-successful-payment__subtitle">
            סיימת את התהליך קניית המתנה
          </h2>
          <button
    style={{ width: '100%', fontSize: '2.2vh' }}
    className="btn copy-link"
    onClick={(e) => { 
      e.preventDefault();  // Prevents any default action
      e.stopPropagation(); 
      setMyEventsActiveTab(0)// Stops event propagation to parent elements
      navigate("/my-events"); 
    }}
  >
    כאן תוכל לפתוח אירוע משלך
  </button>
        </div>
      </section>
    </main>
  );
}
 
export default ThankPayme;
